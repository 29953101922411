<template>
  <div>
    Please visit our <a href="https://primesurveys.com/docs/web">SDK documentation</a>.
  </div>
</template>

<script>
export default {
  created() {
    this.hideLoading()
  },
}
</script>

<style>

</style>
